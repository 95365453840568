const INDIVIDUAL = 'osoba fizyczna'
const COMPANY = 'osoba prawna'

const USERS_IDS = 'Users IDs'

const GTE = '_gte'
const LTE = '_lte'

const COMPANY_RATING_CHOICES = Array.from({ length: 10 }, (_, i) => ({
  id: i + 1,
}))

const COMPANY_NEED_CHOICES = [
  { id: 'N0' },
  { id: 'N1' },
  { id: 'N2' },
  { id: 'N3' },
]

export {
  INDIVIDUAL,
  COMPANY,
  USERS_IDS,
  GTE,
  LTE,
  COMPANY_RATING_CHOICES,
  COMPANY_NEED_CHOICES,
}
