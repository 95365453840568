import {
  BooleanField,
  Datagrid,
  Edit,
  Loading,
  NumberField,
  ReferenceField,
  ResourceProps,
  TextField,
} from 'react-admin'
import { EGroupsFields } from './Groups.type'
import { InputBoolean, InputNumber, InputText } from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import SelectFilter from './components/SelectFilter/SelectFilter'
import MyList from '../../components/MyList/MyList'
import { MIN_TRUNCATE_LIMIT } from '../../components/Field/TruncatedTextField/TruncatedTextField.const'
import { TranslationBooleanField } from 'src/components/Field/TranslationBooleanField/TranslationBooleanField'
import { LanguageInputs } from 'src/components/LanguageInputs/LanguageInputs'
import { useLanguages } from 'src/hooks/useLanguages'
import { DEFAULT_LANGUAGE } from 'src/consts/language'

const groupsFilters = [
  <SelectFilter
    source={EGroupsFields.grupa_id_nad}
    url={EResources.grupas}
    alwaysOn
  />,
]

const GroupsList = () => {
  const { languages, isLoading } = useLanguages()

  if (isLoading) return <Loading />

  return (
    <MyList filters={groupsFilters}>
      <Datagrid rowClick='edit'>
        <TextField source={EGroupsFields.grupa_id} />
        <TextField source={EGroupsFields.nazwa_st} />

        {languages.map((lang) => {
          if (lang.code === DEFAULT_LANGUAGE) return null

          return (
            <TranslationBooleanField
              key={lang.code}
              source={`${EGroupsFields.nazwa_st}_${lang.code}`}
              sortable={false}
            />
          )
        })}

        <TruncatedTextField
          source={EGroupsFields.opis_st}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <NumberField source={EGroupsFields.sort} />
        <TextField source={EGroupsFields.uwagi} />
        <BooleanField source={EGroupsFields.czy_grupakat} />
        <BooleanField source={EGroupsFields.czy_branza} />
        <BooleanField source={EGroupsFields.czy_oferta} />
        <BooleanField source={EGroupsFields.czy_firma} />
        <BooleanField source={EGroupsFields.is_visible} />

        <ReferenceField
          reference={EResources.grupas}
          source={EGroupsFields.grupa_id_nad}
        >
          <TextField source={EGroupsFields.nazwa_out} />
        </ReferenceField>

        <TextField source={EGroupsFields.slug} />
        <TruncatedTextField
          source={EGroupsFields.seo_text_meta_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGroupsFields.seo_title_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGroupsFields.meta_description_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGroupsFields.description_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />

        <TextField source={EGroupsFields.default_availability_period} />
      </Datagrid>
    </MyList>
  )
}

const GroupsEdit = () => (
  <Edit>
    <Form resource={EResources.grupas} method={EMethod.PUT}>
      <InputText source={EGroupsFields.grupa_id} />
      <LanguageInputs
        baseField={EGroupsFields.nazwa_st}
        pattern='underscore'
        isDisabled
      />
      <InputText source={EGroupsFields.opis_st} />
      <InputNumber source={EGroupsFields.sort} />
      <InputText source={EGroupsFields.uwagi} />
      <InputBoolean source={EGroupsFields.czy_grupakat} />
      <InputBoolean source={EGroupsFields.czy_branza} />
      <InputBoolean source={EGroupsFields.czy_oferta} />
      <InputBoolean source={EGroupsFields.czy_firma} />
      <InputBoolean source={EGroupsFields.is_visible} />
      <InputNumber source={EGroupsFields.grupa_id_nad} />
      <InputNumber source={EGroupsFields.default_availability_period} />
      <LanguageInputs baseField={EGroupsFields.slug} pattern='underscore' />
      <LanguageInputs
        baseField={EGroupsFields.seo_text_meta}
        pattern='nested'
      />
      <LanguageInputs baseField={EGroupsFields.seo_title} pattern='nested' />
      <LanguageInputs
        baseField={EGroupsFields.meta_description}
        pattern='nested'
      />
      <LanguageInputs
        baseField={EGroupsFields.description}
        pattern='nested'
        isWysiwyg
      />
    </Form>
  </Edit>
)

export const grupas: ResourceProps = {
  name: EResources.grupas,
  list: GroupsList,
  edit: GroupsEdit,
}
