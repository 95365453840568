import { stringify } from 'query-string'
import { fetchUtils, DataProvider } from 'ra-core'
import { EResources } from '../../types/resource.types'
import { EPartnersFields } from 'src/lists/Partners/Partners.type'
import { EAdsFields } from '../../lists/Ads/Ads.type'

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
export default (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson,
  countHeader: string = 'Content-Range',
): DataProvider => ({
  getList: (resource, params) => {
    Object.keys(params.filter || {}).forEach((key) => {
      if (key.includes('->')) {
        // Hack around dot issue for filters: https://github.com/marmelab/react-admin/issues/2102

        params.filter[`${key.replace('->', '.')}`] = params.filter[key]
      } else {
        params.filter[`${key}`] = params.filter[key]
      }
    })

    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const rangeStart = (page - 1) * perPage
    const rangeEnd = page * perPage - 1

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {}

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
        )
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(
                headers.get('content-range')?.split('/').pop() ?? '0',
                10,
              )
            : parseInt(headers.get(countHeader.toLowerCase()) ?? '0', 10),
      }
    })
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort

    const rangeStart = (page - 1) * perPage
    const rangeEnd = page * perPage - 1

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const options =
      countHeader === 'Content-Range'
        ? {
            // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
            headers: new Headers({
              Range: `${resource}=${rangeStart}-${rangeEnd}`,
            }),
          }
        : {}

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`,
        )
      }
      return {
        data: json,
        total:
          countHeader === 'Content-Range'
            ? parseInt(
                headers.get('content-range')?.split('/').pop() ?? '0',
                10,
              )
            : parseInt(headers.get(countHeader.toLowerCase()) ?? '0', 10),
      }
    })
  },

  update: (resource, params) => {
    if (resource === EResources.partners) {
      const partnersForm = new FormData()

      const { rawFile } = params.data.file

      partnersForm.append(EPartnersFields.file, rawFile)
      partnersForm.append(
        EPartnersFields.active,
        params.data[EPartnersFields.active],
      )

      if (params.data[EPartnersFields.link]) {
        partnersForm.append(
          EPartnersFields.link,
          params.data[EPartnersFields.link],
        )
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}?_method=PUT`, {
        method: 'POST',
        body: partnersForm,
      }).then(({ json }) => ({ data: json }))
    }

    if (resource === EResources.ads) {
      const adsForm = new FormData()

      const fileObj = params.data.file

      if (fileObj && 'rawFile' in fileObj) {
        const { rawFile } = fileObj || {}

        adsForm.append(EAdsFields.file, rawFile)
      }

      adsForm.append(EAdsFields.name, params.data[EAdsFields.name])
      adsForm.append(EAdsFields.active, params.data[EAdsFields.active])
      adsForm.append(EAdsFields.position, params.data[EAdsFields.position])
      adsForm.append(EAdsFields.url, params.data[EAdsFields.url])
      adsForm.append(EAdsFields.ad_size, params.data[EAdsFields.ad_size])

      return httpClient(`${apiUrl}/${resource}/${params.id}?_method=PUT`, {
        method: 'POST',
        body: adsForm,
      }).then(({ json }) => ({ data: json }))
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
        }),
      ),
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {
    if (resource === EResources.partners) {
      const partnersForm = new FormData()

      const { rawFile } = params.data.file

      partnersForm.append(EPartnersFields.file, rawFile)
      partnersForm.append(
        EPartnersFields.active,
        params.data[EPartnersFields.active],
      )

      if (params.data[EPartnersFields.link]) {
        partnersForm.append(
          EPartnersFields.link,
          params.data[EPartnersFields.link],
        )
      }

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: partnersForm,
      }).then(({ json }) => ({ data: json }))
    }

    if (resource === EResources.ads) {
      const adsForm = new FormData()

      const fileObj = params.data.file

      if (fileObj && 'rawFile' in fileObj) {
        const { rawFile } = fileObj || {}

        adsForm.append(EAdsFields.file, rawFile)
      }

      adsForm.append(EAdsFields.name, params.data[EAdsFields.name])
      adsForm.append(EAdsFields.active, params.data[EAdsFields.active])
      adsForm.append(EAdsFields.position, params.data[EAdsFields.position])
      adsForm.append(EAdsFields.url, params.data[EAdsFields.url])
      adsForm.append(EAdsFields.ad_size, params.data[EAdsFields.ad_size])

      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: adsForm,
      }).then(({ json }) => ({ data: json }))
    }

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    }).then(({ json }) => ({ data: json })),

  // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'text/plain',
          }),
        }),
      ),
    ).then((responses) => ({
      data: responses.map(({ json }) => json),
    })),
})
