import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { EMethod } from 'src/hooks/useShowApiErrors'

const API_URL = process.env.REACT_APP_API_URL
const API_PORTAL_URL = process.env.REACT_APP_API_PORTAL_URL

const useSimpleFetch = () => {
  const { value: token } = useLocalStorage('auth')

  const defaultOptions = {
    headers: {
      authorization: token ?? '',
      'Content-Type': 'application/json',
    },
  }

  const simpleFetch = async (
    url: string,
    method: EMethod = EMethod.POST,
    isApiPortal: boolean = false,
  ) => {
    const options = {
      ...defaultOptions,
      method,
    }

    return await fetch(
      `${isApiPortal ? API_PORTAL_URL : API_URL}${url}`,
      options,
    )
  }

  return {
    simpleFetch,
  }
}

export { useSimpleFetch }
