import { useRecordContext, Labeled } from 'react-admin'
import { Stack, Chip } from '@mui/material'
import { TLanguage } from 'src/hooks/useLanguages'

const LanguagesField = () => {
  const record = useRecordContext()

  if (!record?.langs) {
    return null
  }

  return (
    <Labeled label='Active languages'>
      <Stack direction='row' spacing={1}>
        {record.langs.map((lang: TLanguage) => (
          <Chip key={lang.code} label={lang.name} />
        ))}
      </Stack>
    </Labeled>
  )
}

export default LanguagesField
