export enum EGrupakatsFields {
  id = 'id',
  grupa_kat_id = 'grupa_kat_id',
  czy_oferta = 'czy_oferta',
  description = 'description',
  description_pl = 'description.pl',
  name_alt = 'name_alt',
  grupa_id = 'grupa_id',
  grupa_kat_id_nad = 'grupa_kat_id_nad',
  nazwa_in = 'nazwa_in',
  nazwa_out = 'nazwa_out',
  nazwa_st = 'nazwa_st',
  nazwa_st_en = 'nazwa_st_en',
  nazwa_st_de = 'nazwa_st_de',
  opis_in = 'opis_in',
  opis_out = 'opis_out',
  opis_st = 'opis_st',
  seo_text_meta = 'seo_text_meta',
  seo_text_meta_pl = 'seo_text_meta.pl',
  seo_title = 'seo_title',
  seo_title_pl = 'seo_title.pl',
  slug = 'slug',
  sort = 'sort',
  uwagi = 'uwagi',
  default_pos_type = 'default_pos_type',
}
