import { useRecordContext } from 'react-admin'
import {
  HOURS_IN_DAY,
  MILLISECONDS_IN_SECOND,
  SECONDS_IN_HOUR,
} from './DaysCountField.const'

const DaysCountField = ({ source }: { source: string }) => {
  const record = useRecordContext()
  if (!record) return null

  const currentDate = new Date()
  const lastLogin = record[source]

  const timeDiff = Math.abs(
    currentDate.getTime() - new Date(lastLogin).getTime(),
  )
  const dayDiff = Math.floor(
    timeDiff / (MILLISECONDS_IN_SECOND * SECONDS_IN_HOUR * HOURS_IN_DAY),
  )

  return <p>{lastLogin ? dayDiff : ''}</p>
}

export default DaysCountField
