import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useState } from 'react'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { EMethod } from 'src/hooks/useShowApiErrors'
import { useNotify } from 'react-admin'

const EN_LANG = 'en'
const DE_LANG = 'de'

type TLanguages = typeof DE_LANG | typeof EN_LANG

const TranslationsPage = () => {
  const [language, setLanguage] = useState<TLanguages>(EN_LANG)
  const [fileToUpload, setFileToUpload] = useState<File | null>(null)

  const notify = useNotify()

  const { value: token } = useLocalStorage('auth')

  const requestOptions = {
    headers: {
      authorization: token ?? '',
    },
  }

  const handleChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as TLanguages)
  }

  const downloadTranslationsFile = async () => {
    const downloadedFile = await fetch(
      process.env.REACT_APP_API_URL + `/translations/${language}`,
      { ...requestOptions, method: EMethod.GET },
    )

    if (!downloadedFile.ok) {
      notify('Cos poszlo nie tak podczas pobierania', {
        type: 'error',
      })

      return
    }

    const file = await downloadedFile.blob()

    const element = document.createElement('a')
    element.href = URL.createObjectURL(file)
    element.download = `translations_${language}.json`
    document.body.appendChild(element)
    element.click()
    element.remove()
  }

  const uploadTranslations = async () => {
    const formData = new FormData()

    if (!fileToUpload) return

    formData.append('file', fileToUpload)

    const uploadedFile = await fetch(
      process.env.REACT_APP_API_URL + `/translations/${language}`,
      {
        ...requestOptions,
        method: EMethod.POST,
        body: formData,
      },
    )

    if (!uploadedFile.ok) {
      notify('Cos poszlo nie tak podczas pobierania', {
        type: 'error',
      })

      return
    }

    notify('Wgrano tlumaczenia pomyslnie', {
      type: 'success',
    })

    setFileToUpload(null)
  }

  return (
    <Stack
      alignItems={'center'}
      justifyContent={'center'}
      sx={{ marginTop: '12px' }}
    >
      <Stack sx={{ maxWidth: '300px', width: '100%' }} spacing={3}>
        <Select
          id='language'
          value={language}
          label='Jezyk'
          onChange={handleChange}
        >
          <MenuItem value={EN_LANG}>Angielski</MenuItem>
          <MenuItem value={DE_LANG}>Niemiecki</MenuItem>
        </Select>

        {fileToUpload ? (
          <Button
            variant='contained'
            component='label'
            color='error'
            onClick={uploadTranslations}
          >
            Wgraj tlumaczenia
          </Button>
        ) : (
          <Button variant='outlined' color='info' component={'label'}>
            Wybierz plik z tlumaczniami
            <input
              hidden
              max={1}
              type='file'
              onChange={({ target }) => {
                const files = target.files

                if (files && files.length > 0) {
                  setFileToUpload(files[0])
                }
              }}
            />
          </Button>
        )}

        <Button variant='contained' onClick={downloadTranslationsFile}>
          Pobierz Tlumaczenia
        </Button>
      </Stack>
    </Stack>
  )
}

export default TranslationsPage
