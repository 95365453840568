import { BooleanField, useRecordContext } from 'react-admin'
import { EGroupsFields } from 'src/lists/Groups/Groups.type'

interface ITranslationBooleanFieldProps {
  source: string
  sortable?: boolean
}

const TranslationBooleanField = ({
  source,
  sortable = false,
}: ITranslationBooleanFieldProps) => {
  const record = useRecordContext()
  const baseTranslation = record[EGroupsFields.nazwa_st]
  const compareTranslation = record[source]

  const isTranslationProvided = baseTranslation !== compareTranslation

  return (
    <BooleanField
      record={{ value: isTranslationProvided }}
      source='value'
      sortable={sortable}
    />
  )
}

export { TranslationBooleanField }
