import { ImageField, ImageInput, useEditContext, useInput } from 'react-admin'
import { useEffect, useState } from 'react'
import { fetchFile } from '../../../utils/fetchFile'
import { useFormContext } from 'react-hook-form'

function formatLogo(value: unknown) {
  if (!value || typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

const FILE = 'file'

const ChangeImage = () => {
  const [image, setImage] = useState<null | {
    rawFile: File
  }>(null)
  const { setValue } = useFormContext()
  const { record, isLoading } = useEditContext()
  const { field } = useInput({ source: FILE })

  useEffect(() => {
    if (!record) return
    const getFiles = async () => {
      const { image_url } = record
      const imageResponse = await fetchFile({ fileUrl: image_url })
      if (!imageResponse) return

      setValue(FILE, { rawFile: imageResponse })
      setImage({ rawFile: imageResponse })

      const imageBlob = new Blob([imageResponse], { type: imageResponse.type })

      field.onChange({
        rawFile: imageResponse,
        title: imageResponse.name,
        src: URL.createObjectURL(imageBlob),
      })
    }

    getFiles()
  }, [record])

  useEffect(() => {
    if (field.value === null) {
      setValue(FILE, image)
    }
  }, [field.value])

  if (isLoading) return null
  return (
    <ImageInput format={formatLogo} source={FILE}>
      <ImageField source={'src'} />
    </ImageInput>
  )
}

export default ChangeImage
