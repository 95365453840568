import { ReactNode } from 'react'
import { EditProps, RaRecord, Edit } from 'react-admin'

export const MyEdit = <RecordType extends RaRecord = any>(
  props: EditProps<RecordType> & { children: ReactNode },
) => {
  const { children, ...editProps } = props
  return (
    <Edit mutationMode='pessimistic' {...editProps}>
      {children}
    </Edit>
  )
}
