import {
  BooleanField,
  Datagrid,
  Edit,
  Loading,
  NumberField,
  ResourceProps,
  SelectInput,
  TextField,
} from 'react-admin'
import { EGrupakatsFields } from './Grupakats.type'
import { InputBoolean, InputNumber, InputText } from 'src/components/Input'
import { TruncatedTextField } from 'src/components/Field'
import { EResources } from 'src/types/resource.types'
import CategoryField from './components/CategoryField'
import { GOOD, SERVICE } from './components/CategoryField.const'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import SelectFilter from '../Groups/components/SelectFilter/SelectFilter'
import MyList from '../../components/MyList/MyList'
import { MIN_TRUNCATE_LIMIT } from '../../components/Field/TruncatedTextField/TruncatedTextField.const'
import { TranslationBooleanField } from 'src/components/Field/TranslationBooleanField/TranslationBooleanField'
import { LanguageInputs } from 'src/components/LanguageInputs/LanguageInputs'
import { DEFAULT_LANGUAGE } from 'src/consts/language'
import { useLanguages } from 'src/hooks/useLanguages'

const grupakatsFilters = [
  <SelectFilter
    source={EGrupakatsFields.grupa_kat_id_nad}
    url={'grupakats?filter={"grupa_kat_id_nad": null}'}
    alwaysOn
  />,
]

const GrupakatsList = () => {
  const { languages, isLoading } = useLanguages()

  if (isLoading) return <Loading />

  return (
    <MyList filters={grupakatsFilters}>
      <Datagrid rowClick='edit'>
        <NumberField source={EGrupakatsFields.id} />
        <NumberField source={EGrupakatsFields.grupa_kat_id} />
        <BooleanField source={EGrupakatsFields.czy_oferta} />
        <TruncatedTextField
          source={EGrupakatsFields.description_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <NumberField source={EGrupakatsFields.grupa_id} />
        <NumberField source={EGrupakatsFields.grupa_kat_id_nad} />
        <TextField source={EGrupakatsFields.name_alt} />
        <TextField source={EGrupakatsFields.nazwa_in} />
        <TextField source={EGrupakatsFields.nazwa_out} />
        <TextField source={EGrupakatsFields.nazwa_st} />
        {languages.map((lang) => {
          if (lang.code === DEFAULT_LANGUAGE) return null

          return (
            <TranslationBooleanField
              key={lang.code}
              source={`${EGrupakatsFields.nazwa_st}_${lang.code}`}
              sortable={false}
            />
          )
        })}

        <TruncatedTextField
          source={EGrupakatsFields.opis_in}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGrupakatsFields.opis_out}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGrupakatsFields.opis_st}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGrupakatsFields.seo_text_meta_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TruncatedTextField
          source={EGrupakatsFields.seo_title_pl}
          limit={MIN_TRUNCATE_LIMIT}
        />
        <TextField source={EGrupakatsFields.slug} />
        <NumberField source={EGrupakatsFields.sort} />
        <CategoryField source={EGrupakatsFields.default_pos_type} />
        <TruncatedTextField
          source={EGrupakatsFields.uwagi}
          limit={MIN_TRUNCATE_LIMIT}
        />
      </Datagrid>
    </MyList>
  )
}

const GrupakatsEdit = () => (
  <Edit>
    <Form method={EMethod.PUT} resource={EResources.grupakats}>
      <InputNumber source={EGrupakatsFields.id} />
      <InputNumber source={EGrupakatsFields.grupa_kat_id} />
      <InputBoolean source={EGrupakatsFields.czy_oferta} />
      <LanguageInputs
        baseField={EGrupakatsFields.description}
        pattern='nested'
        isWysiwyg
      />
      <InputNumber source={EGrupakatsFields.grupa_id} />
      <InputNumber source={EGrupakatsFields.grupa_kat_id_nad} />
      <InputText source={EGrupakatsFields.name_alt} />
      <InputText source={EGrupakatsFields.nazwa_in} />
      <InputText source={EGrupakatsFields.nazwa_out} />
      <LanguageInputs
        baseField={EGrupakatsFields.nazwa_st}
        pattern='underscore'
        isDisabled
      />
      <InputText source={EGrupakatsFields.opis_in} />
      <InputText source={EGrupakatsFields.opis_out} />
      <InputText source={EGrupakatsFields.opis_st} />
      <LanguageInputs
        baseField={EGrupakatsFields.seo_text_meta}
        pattern='nested'
      />
      <LanguageInputs baseField={EGrupakatsFields.seo_title} pattern='nested' />
      <LanguageInputs baseField={EGrupakatsFields.slug} pattern='underscore' />
      <InputNumber source={EGrupakatsFields.sort} />
      <SelectInput
        source={EGrupakatsFields.default_pos_type}
        choices={[
          { id: 0, name: GOOD },
          { id: 1, name: SERVICE },
        ]}
      />
      <InputText source={EGrupakatsFields.uwagi} />
    </Form>
  </Edit>
)

export const grupakats: ResourceProps = {
  name: EResources.grupakats,
  list: GrupakatsList,
  edit: GrupakatsEdit,
}
