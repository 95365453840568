import { useRecordContext } from 'react-admin'
import { InputText } from '../Input/InputText/InputText'
import { InputWysiwyg } from '../Input/InputWysiwyg/InputWysiwyg'
import { DEFAULT_LANGUAGE } from 'src/consts/language'

interface ILanguageInputsProps<T> {
  baseField: T
  pattern: 'underscore' | 'nested'
  label?: string
  isWysiwyg?: boolean
  isDisabled?: boolean | ((lang: string) => boolean)
  isReadOnly?: boolean | ((lang: string) => boolean)
}

const LanguageInputs = <T extends keyof Record<string, string>>({
  baseField,
  pattern,
  label,
  isWysiwyg = false,
  isDisabled = false,
  isReadOnly = false,
}: ILanguageInputsProps<T>) => {
  const record = useRecordContext()
  if (!record) return null

  const InputComponent = isWysiwyg ? InputWysiwyg : InputText

  if (pattern === 'nested') {
    const nestedValue = record[baseField]
    if (!nestedValue) return null

    const languages = Object.keys(nestedValue).sort((a, b) => {
      if (a === DEFAULT_LANGUAGE) return -1
      if (b === DEFAULT_LANGUAGE) return 1
      return a.localeCompare(b)
    })

    return (
      <>
        {languages.map((lang) => {
          const isFieldDisabled =
            typeof isDisabled === 'function' ? isDisabled(lang) : isDisabled
          const isFieldReadOnly =
            typeof isReadOnly === 'function' ? isReadOnly(lang) : isReadOnly

          return (
            <InputComponent
              key={`${baseField}.${lang}`}
              source={`${baseField}.${lang}`}
              label={label ? `${label} ${lang}` : undefined}
              disabled={isFieldDisabled}
              readOnly={isFieldReadOnly}
            />
          )
        })}
      </>
    )
  }

  const languageFields = Object.keys(record)
    .filter((key) => key === baseField || key.startsWith(baseField + '_'))
    .sort((a, b) => {
      if (a === baseField) return -1
      if (b === baseField) return 1
      return a.localeCompare(b)
    })

  return (
    <>
      {languageFields.map((field) => {
        const isFieldDisabled =
          typeof isDisabled === 'function' ? isDisabled(field) : isDisabled

        return (
          <InputComponent
            key={field}
            source={field}
            disabled={field !== baseField && isFieldDisabled}
          />
        )
      })}
    </>
  )
}

export { LanguageInputs }
