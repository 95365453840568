import { useCreate, useNotify, useRedirect, useUpdate } from 'react-admin'
import { useCallback } from 'react'
import { EResources } from '../types/resource.types'

export enum EMethod {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
}

const useShowApiErrors = (
  resource: EResources,
  method: EMethod,
  id: number | null,
) => {
  const redirect = useRedirect()
  const notify = useNotify()

  const [create] = useCreate()
  const [update] = useUpdate()

  const save = useCallback(
    async (values: Record<string, unknown>) => {
      const errorsApi: Record<string, string> = {}

      try {
        if (method === EMethod.POST) {
          await create(resource, { data: values }, { returnPromise: true })
          notify('Utworzono', {
            type: 'info',
          })
        } else {
          if (!id) return

          await update(resource, { id, data: values }, { returnPromise: true })

          notify('Zedytowano', {
            type: 'info',
          })
        }
        redirect('list', resource)
      } catch (error: any) {
        if (error.body) {
          const apiErrors: {
            [key: string]: string[]
          } = error.body['field-error']

          for (const [field, message] of Object.entries(apiErrors)) {
            errorsApi[field] = message[0].replace(/\$/g, '')
          }
        }

        return errorsApi
      }
    },
    [create, notify, redirect],
  )

  return { save }
}

export { useShowApiErrors }
