export enum EGroupsFields {
  id = 'id',
  grupa_id = 'grupa_id',
  nazwa_st = 'nazwa_st',
  nazwa_st_en = 'nazwa_st_en',
  nazwa_st_de = 'nazwa_st_de',
  nazwa_in = 'nazwa_in',
  nazwa_out = 'nazwa_out',
  opis_st = 'opis_st',
  opis_in = 'opis_in',
  opis_out = 'opis_out',
  sort = 'sort',
  uwagi = 'uwagi',
  czy_grupakat = 'czy_grupakat',
  czy_branza = 'czy_branza',
  czy_oferta = 'czy_oferta',
  czy_firma = 'czy_firma',
  is_visible = 'is_visible',
  grupa_id_nad = 'grupa_id_nad',
  slug = 'slug',
  seo_text_meta = 'seo_text_meta',
  seo_text_meta_pl = 'seo_text_meta.pl',
  seo_title = 'seo_title',
  seo_title_pl = 'seo_title.pl',
  lft = 'lft',
  rht = 'rht',
  meta_description = 'meta_description',
  meta_description_pl = 'meta_description.pl',
  description = 'description',
  description_pl = 'description.pl',
  default_availability_period = 'default_availability_period',
}
