import { Menu as RAMenu } from 'react-admin'
import TranslateIcon from '@mui/icons-material/Translate'
import { TRANSLATIONS_PAGE } from 'src/consts/pages'

const Menu = () => (
  <RAMenu>
    <RAMenu.ResourceItems />

    <RAMenu.Item
      to={TRANSLATIONS_PAGE}
      primaryText='Tlumaczenia'
      leftIcon={<TranslateIcon />}
      // Providing default values for required props to satisfy TypeScript's type checking for MUI
      placeholder=''
      onPointerEnterCapture={() => {}}
      onPointerLeaveCapture={() => {}}
    />
  </RAMenu>
)

export default Menu
