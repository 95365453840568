import { Admin, Resource, fetchUtils, Options, CustomRoutes } from 'react-admin'
import { Route } from 'react-router-dom'

import simpleRestProvider from './providers/dataProvider/dataProvider'
import authProvider from './providers/authProvider/authProvider'

import { users } from './lists/Users/Users'
import { offers } from './lists/Offers/Offers'
import { companies } from './lists/Companies/Companies'
import { ads } from './lists/Ads/Ads'
import { pages } from './lists/Pages/Pages'
import { grupas } from './lists/Groups/Groups'
import { grupakats } from './lists/Grupakats/Grupakats'
import { references } from './lists/References/References'
import { contacts } from './lists/Contacts/Contacts'
import { systemConfigs } from './lists/SystemConfigs/SystemConfigs'
import { postalCodes } from './lists/PostalCodes/PostalCodes'
import { redirects } from './lists/Redirects/Redirects'
import { partners } from './lists/Partners/Partners'
import { appReviews } from './lists/AppReviews/AppReviews'
import { companiesReviews } from './lists/CompaniesReviews/CompaniesReviews'
import { inquiries } from './lists/Inquiries/Inquiries'
import { Layout } from './components/Layout/Layout'
import { positions } from './lists/Positions/Positions'
import { accountTypes } from './lists/AccountTypes/AccountTypes'
import { accountTypePermissions } from './lists/AccountTypePermissions/AccountTypePermissions'
import Translations from 'src/pages/Translations'
import { TRANSLATIONS_PAGE } from 'src/consts/pages'

const httpClient = (url: string, options: Options = {}) => {
  const reqHeaders = new Headers(options.headers)

  const token = localStorage.getItem('auth')

  reqHeaders.set('Authorization', token ?? '')
  reqHeaders.set('Accept', 'application/json')

  options.headers = reqHeaders

  return fetchUtils.fetchJson(url, options)
}

const dataProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL ?? '',
  httpClient,
)

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      requireAuth
    >
      <Resource {...users} />

      <Resource {...companies} />

      <Resource {...companiesReviews} />

      <Resource {...offers} />

      <Resource {...pages} />

      <Resource {...grupas} />

      <Resource {...grupakats} />

      <Resource {...ads} />

      <Resource {...references} />

      <Resource {...contacts} />

      <Resource {...systemConfigs} />

      <Resource {...postalCodes} />

      <Resource {...redirects} />

      <Resource {...partners} />

      <Resource {...appReviews} />

      <Resource {...inquiries} />

      <Resource {...positions} />

      <Resource {...accountTypes} />

      <Resource {...accountTypePermissions} />

      <CustomRoutes>
        <Route path={TRANSLATIONS_PAGE} element={<Translations />} />
      </CustomRoutes>
    </Admin>
  )
}

export default App
