import { Stack, CircularProgress } from '@mui/material'

const Loader = () => {
  return (
    <Stack alignItems='center' padding={2}>
      <CircularProgress size={24} />
    </Stack>
  )
}

export default Loader
