import {
  Create,
  Datagrid,
  Edit,
  ListProps,
  ResourceProps,
  TextField,
  TextInput,
} from 'react-admin'
import { EResources } from '../../types/resource.types'
import { EPostalCodesFields } from './PostalCodes.type'
import { InputText } from '../../components/Input'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const postalCodesFilters = [
  <TextInput source={EPostalCodesFields.postal_code} alwaysOn />,
]

const PostalCodesList = (props: ListProps) => (
  <MyList {...props} filters={postalCodesFilters} hasCreate={true}>
    <Datagrid rowClick='edit'>
      <TextField source={EPostalCodesFields.latitude} />
      <TextField source={EPostalCodesFields.longitude} />
      <TextField source={EPostalCodesFields.postal_code} />
    </Datagrid>
  </MyList>
)

const PostalCodeCreate = () => (
  <Create>
    <Form resource={EResources.postalCodes} method={EMethod.POST}>
      <InputText source={EPostalCodesFields.latitude} />
      <InputText source={EPostalCodesFields.longitude} />
      <InputText source={EPostalCodesFields.postal_code} />
    </Form>
  </Create>
)

const PostalCodeEdit = () => (
  <Edit>
    <Form resource={EResources.postalCodes} method={EMethod.PUT}>
      <InputText source={EPostalCodesFields.latitude} />
      <InputText source={EPostalCodesFields.longitude} />
      <InputText source={EPostalCodesFields.postal_code} />
    </Form>
  </Edit>
)

export const postalCodes: ResourceProps = {
  name: EResources.postalCodes,
  list: PostalCodesList,
  create: PostalCodeCreate,
  edit: PostalCodeEdit,
}
