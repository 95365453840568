import { useRecordContext } from 'react-admin'
import { LanguageInputs } from 'src/components/LanguageInputs/LanguageInputs'
import { TLanguage } from 'src/hooks/useLanguages'

interface IPositionLanguageInputProps {
  baseField: string
  label?: string
  isWysiwyg?: boolean
}

const PositionLanguageInput = ({
  baseField,
  label,
  isWysiwyg = false,
}: IPositionLanguageInputProps) => {
  const record = useRecordContext()

  if (!record) return null

  const activeLanguages =
    record.company?.langs.map((lang: TLanguage) => lang.code) || []

  return (
    <LanguageInputs
      baseField={baseField}
      pattern='nested'
      label={label}
      isWysiwyg={isWysiwyg}
      isReadOnly={(lang) => !activeLanguages.includes(lang)}
    />
  )
}

export default PositionLanguageInput
