import { List, ListProps, TopToolbar, CreateButton } from 'react-admin'
import { RaRecord } from 'ra-core'
import { Box } from '@mui/material'
import MyFilterButton from './components/MyFilterButton/MyFilterButton'
import MyFilterForm from './components/MyFilterForm/MyFilterForm'

const MyList = <RecordType extends RaRecord = any>(
  props: ListProps<RecordType>,
) => {
  const { children, filters, ...listProps } = props

  const isCreateButtonVisible = listProps.hasCreate

  return (
    <List
      exporter={false}
      {...listProps}
      actions={
        <Box
          width='100%'
          maxWidth={'calc(100vw - 280px)'}
          display={'flex'}
          flexWrap={'wrap'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
          marginRight={'auto'}
        >
          <TopToolbar
            sx={{
              minHeight: '20px !important',
            }}
          >
            {filters && <MyFilterButton />}
            {isCreateButtonVisible && <CreateButton />}
          </TopToolbar>
          {filters && <MyFilterForm filters={filters} />}
        </Box>
      }
    >
      {children}
    </List>
  )
}

export default MyList
