import {
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  EditButton,
  EditProps,
  ListProps,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ResourceProps,
  SelectInput,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
} from 'react-admin'
import { EOffersFields } from 'src/lists/Offers/Offers.type'
import { InputNumber, InputText } from 'src/components/Input'
import { EResources } from 'src/types/resource.types'
import { ECompaniesFields } from 'src/lists/Companies/Companies.type'
import { EUsersFields } from 'src/lists/Users/Users.type'
import { MyEdit } from 'src/components/MyEdit/MyEdit'
import { CompanyReferenceInput } from '../Companies/components/CompanyReferenceInput/CompanyReferenceInput'
import { sortByUsers } from '../Users/Users'
import { sortByCompany } from '../Companies/Companies'
import { EOfferStatusesFields } from '../OfferStatuses/OfferStatuses.type'
import Form from '../../components/Form/Form'
import { EMethod } from '../../hooks/useShowApiErrors'
import MyList from '../../components/MyList/MyList'

const offersFilters = [
  <ReferenceInput
    reference={EResources.companies}
    source={EOffersFields.company_id}
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({
        [ECompaniesFields.name]: searchText,
      })}
      label='Company'
      source={ECompaniesFields.name}
      optionText={ECompaniesFields.name}
    />
  </ReferenceInput>,

  <ReferenceInput
    reference={EResources.companies}
    source={EOffersFields.company_id}
    alwaysOn
  >
    <AutocompleteInput
      filterToQuery={(searchText) => ({
        [ECompaniesFields.nip]: searchText,
      })}
      label='NIP'
      source={ECompaniesFields.nip}
      optionText={ECompaniesFields.nip}
    />
  </ReferenceInput>,
]

const OffersList = (props: ListProps) => (
  <MyList {...props} filters={offersFilters} hasCreate={true}>
    <Datagrid rowClick='show'>
      <NumberField source={EOffersFields.id} />
      <NumberField source={EOffersFields.category} />

      <ReferenceField
        sortBy={sortByUsers(EUsersFields.id)}
        reference={EResources.users}
        source={EOffersFields.user_id}
        link='show'
      >
        <TextField source={EUsersFields.id} />
      </ReferenceField>

      <ReferenceField
        sortBy={sortByCompany(ECompaniesFields.id)}
        reference={EResources.companies}
        source={EOffersFields.company_id}
        link='show'
      >
        <TextField source={ECompaniesFields.name} />
      </ReferenceField>

      <TextField source={EOffersFields.name} />
      <DateField source={EOffersFields.created} />
      <DateField source={EOffersFields.modified} />
      <EditButton label='Edit' />
    </Datagrid>
  </MyList>
)

const OffersShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <NumberField source={EOffersFields.id} />
      <NumberField source={EOffersFields.category} />
      <ReferenceField
        reference={EResources.users}
        source={EOffersFields.user_id}
      >
        <TextField source={EUsersFields.id} />
      </ReferenceField>
      <ReferenceField
        reference={EResources.companies}
        source={EOffersFields.company_id}
      >
        <TextField source={ECompaniesFields.name} />
      </ReferenceField>
      <TextField source={EOffersFields.slug_url} />
      <DateField source={EOffersFields.created} />
      <DateField source={EOffersFields.modified} />
    </SimpleShowLayout>
  </Show>
)

const OffersEdit = (props: EditProps) => (
  <MyEdit {...props} redirect='list'>
    <Form method={EMethod.PUT} resource={EResources.offers}>
      <InputNumber source={EOffersFields.category} />
      <ReferenceInput
        reference={EResources.users}
        source={EOffersFields.user_id}
      >
        <SelectInput
          label='User'
          source={EUsersFields.id}
          optionText={EUsersFields.id}
        />
      </ReferenceInput>

      <ReferenceInput
        reference={EResources.offerStatuses}
        source={EOffersFields.offerstatus_id}
      >
        <AutocompleteInput
          label='Offer status'
          source={EOfferStatusesFields.id}
          optionText={EOfferStatusesFields.name}
        />
      </ReferenceInput>

      <CompanyReferenceInput />

      <InputText source={EOffersFields.slug_url} />
    </Form>
  </MyEdit>
)

const OffersCreate = (props: CreateProps) => (
  <Create {...props}>
    <Form method={EMethod.POST} resource={EResources.offers}>
      <InputNumber source={EOffersFields.category} />
      <ReferenceInput
        reference={EResources.users}
        source={EOffersFields.user_id}
      >
        <AutocompleteInput
          label='User'
          source={EUsersFields.id}
          optionText={EUsersFields.id}
        />
      </ReferenceInput>

      <ReferenceInput
        reference={EResources.offerStatuses}
        source={EOffersFields.offerstatus_id}
      >
        <AutocompleteInput
          label='Offer status'
          source={EOfferStatusesFields.id}
          optionText={EOfferStatusesFields.name}
        />
      </ReferenceInput>

      <CompanyReferenceInput />

      <InputText source={EOffersFields.slug_url} />
    </Form>
  </Create>
)

export const offers: ResourceProps = {
  name: EResources.offers,
  list: OffersList,
  show: OffersShow,
  edit: OffersEdit,
  create: OffersCreate,
}
