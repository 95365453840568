import React from 'react'
import { useRecordContext, useInput, BooleanInput } from 'react-admin'
import { ECompaniesFields } from '../../Companies.type'
import { TLanguage, useLanguages } from 'src/hooks/useLanguages'
import Loader from 'src/components/UI/Loader'

const LanguageSelector = () => {
  const { languages, isLoading } = useLanguages()
  const record = useRecordContext()

  const {
    field: { onChange, value },
  } = useInput({
    source: ECompaniesFields.lang_codes,
    defaultValue: record?.langs?.map((lang: TLanguage) => lang.code) || [],
  })

  const handleLanguageChange = (langCode: string, checked: boolean) => {
    const currentCodes = (value || []) as string[]
    const newCodes = checked
      ? [...currentCodes, langCode]
      : currentCodes.filter((code: string) => code !== langCode)
    onChange(newCodes)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      {languages.map((lang) => (
        <BooleanInput
          key={lang.code}
          name={ECompaniesFields.lang_codes}
          source={ECompaniesFields.lang_codes}
          label={lang.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleLanguageChange(lang.code, e.target.checked)
          }
          checked={Array.isArray(value) && value.includes(lang.code)}
        />
      ))}
    </>
  )
}

export default LanguageSelector
