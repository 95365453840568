import { DefaultEditorOptions, RichTextInput } from 'ra-input-rich-text'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import WysiwygToolbar from './components/WysiwygToolbar'

interface IInputWysiwygProps {
  source: string
  disabled?: boolean
  readOnly?: boolean
}

const InputWysiwyg = ({ source, disabled, readOnly }: IInputWysiwygProps) => {
  return (
    <RichTextInput
      editorOptions={{
        ...DefaultEditorOptions,
        extensions: [
          ...(DefaultEditorOptions.extensions ?? []),
          HorizontalRule,
        ],
      }}
      name={source}
      source={source}
      fullWidth
      toolbar={<WysiwygToolbar />}
      disabled={disabled}
      readOnly={readOnly}
    />
  )
}

export { InputWysiwyg }
