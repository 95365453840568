import React from 'react'
import { FunctionField, useAuthProvider, Button } from 'react-admin'
import { EUsersFields } from '../../../lists/Users/Users.type'

interface ILoginFieldProps {
  source: string
  label?: string
}

const LoginField = ({ source, label }: ILoginFieldProps) => {
  const authProvider = useAuthProvider()
  const loginAsUser = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    { userId }: { userId: string },
  ) => {
    e.stopPropagation()

    const response = await authProvider.loginAsUser({ userId })

    if (response.token) {
      window.open(
        `${process.env.REACT_APP_PORTAL_URL}/login-as?token=${response.token}`,
        '_blank',
      )
    }
  }

  return (
    <FunctionField
      source={source}
      label={label}
      render={(record: Record<string, string>) => {
        const userId = record[EUsersFields.id]
        const email = record[source]

        if (!email) return email
        return (
          <Button label={email} onClick={(e) => loginAsUser(e, { userId })} />
        )
      }}
    />
  )
}

export { LoginField }
