import { useState, useEffect } from 'react'
import { useNotify } from 'react-admin'
import { useSimpleFetch } from './useSimpleFetch'
import { EMethod } from './useShowApiErrors'
import { DEFAULT_LANGUAGE } from 'src/consts/language'

export type TLanguage = {
  name: string
  code: string
}

export type TUseLanguages = {
  languages: TLanguage[]
  isLoading: boolean
}

export const useLanguages = (): TUseLanguages => {
  const [languages, setLanguages] = useState<TLanguage[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const notify = useNotify()
  const { simpleFetch } = useSimpleFetch()

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        setIsLoading(true)

        const response = await simpleFetch('/languages', EMethod.GET, true)
        const result = await response.json()

        const sortedLanguages = result.data.sort(
          (a: TLanguage, b: TLanguage) => {
            if (a.code === DEFAULT_LANGUAGE) return -1
            if (b.code === DEFAULT_LANGUAGE) return 1
            return a.name.localeCompare(b.name)
          },
        )

        setLanguages(sortedLanguages)
      } catch (error) {
        notify('Błąd podczas pobierania języków', { type: 'error' })
      } finally {
        setIsLoading(false)
      }
    }

    fetchLanguages()
  }, [])

  return { languages, isLoading }
}
