import { FunctionField } from 'react-admin'
import { DEFAULT_TRUNCATE_LIMIT } from './TruncatedTextField.const'
import { getByPath } from 'dot-path-value'

interface ITruncatedTextFieldProps {
  source: string
  limit?: number
}

const TruncatedTextField = ({
  source,
  limit = DEFAULT_TRUNCATE_LIMIT,
}: ITruncatedTextFieldProps) => {
  return (
    <FunctionField
      source={source}
      render={(record: Record<string, string>) => {
        const str = getByPath(record, source)

        if (!str) return str
        return str.length > 20 ? str.slice(0, limit) + '...' : str
      }}
    />
  )
}

export { TruncatedTextField }
