const DEFAULT_LANGUAGE = 'pl'

const NESTED_DEFAULT_LANGUAGE_KEY = '.pl'

const UNDERSCORE_DEFAULT_LANGUAGE_KEY = '_pl'

export {
  DEFAULT_LANGUAGE,
  NESTED_DEFAULT_LANGUAGE_KEY,
  UNDERSCORE_DEFAULT_LANGUAGE_KEY,
}
