import { useInput } from 'react-admin'
import { ECompaniesFields } from '../../Companies.type'
import { LanguageInputs } from 'src/components/LanguageInputs/LanguageInputs'

interface ICompanyLanguageInputProps {
  baseField: string
  label?: string
}

const CompanyLanguageInput = ({
  baseField,
  label,
}: ICompanyLanguageInputProps) => {
  const {
    field: { value },
  } = useInput({ source: ECompaniesFields.lang_codes })

  return (
    <LanguageInputs
      baseField={baseField}
      pattern='nested'
      label={label}
      isReadOnly={(lang) => !value.includes(lang)}
    />
  )
}

export default CompanyLanguageInput
