import * as React from 'react'
import {
  AppBar as AdminAppBar,
  useNotify,
  Button,
  AppBarProps,
} from 'react-admin'
import { EMethod } from '../../hooks/useShowApiErrors'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const AppBar = (props: AppBarProps) => {
  const notify = useNotify()
  const { value: token } = useLocalStorage('auth')

  const syncWithJobHandler = async () => {
    const options = {
      method: EMethod.POST,
      headers: {
        authorization: token ?? '',
      },
    }

    const grupasResponse = fetch(
      process.env.REACT_APP_API_URL + '/grupas/generateSlugs',
      options,
    )

    const grupaKatsResponse = fetch(
      process.env.REACT_APP_API_URL + '/grupakats/generateSlugs',
      options,
    )

    const result = await Promise.all([grupasResponse, grupaKatsResponse])

    if (!result[0].ok || !result[1].ok) {
      notify('Wystąpił błąd podczas synchronizacji!', { type: 'error' })
      return
    }

    notify('Zsychronizowano', { type: 'success' })
  }

  return (
    <AdminAppBar sx={{ width: '100%' }} {...props}>
      <Button
        onClick={syncWithJobHandler}
        variant={'contained'}
        sx={{ ml: 'auto', mr: 2, lineHeight: 'normal' }}
        label={'Synchronizuj z JOBem'}
      />
    </AdminAppBar>
  )
}
