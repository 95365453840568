import { AuthProvider } from 'react-admin'

type Credentials = {
  username: string
  password: string
}

const LOGIN_URL = `${process.env.REACT_APP_API_PORTAL_URL}/login`

const authProvider: AuthProvider = {
  login: async ({ username, password }: Credentials) => {
    const request = new Request(LOGIN_URL, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((res) => {
        if (res.token) {
          localStorage.setItem('auth', 'Bearer ' + res.token)
          localStorage.removeItem('not_authenticated')
          return
        }
        localStorage.setItem('not_authenticated', 'true')
        return Promise.reject()
      })
  },
  checkAuth: () => {
    // Required for the authentication to work
    const token = localStorage.getItem('auth')
    if (token) return Promise.resolve()

    return Promise.reject()
  },
  getPermissions: () => {
    // Required for the authentication to work
    const token = localStorage.getItem('auth')
    if (token) return Promise.resolve()

    return Promise.reject()
  },
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth')
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  logout: () => {
    localStorage.removeItem('auth')
    return Promise.resolve()
  },
  loginAsUser: async ({ userId }: { userId: string }) => {
    const token = localStorage.getItem('auth')

    const request = new Request(
      `${process.env.REACT_APP_API_URL}/loginAs/${userId}`,
      {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          authorization: token ?? '',
        }),
      },
    )

    try {
      const response = await fetch(request)

      return response.json()
    } catch (err) {
      return Promise.reject()
    }
  },
}

export default authProvider
