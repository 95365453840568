export enum ECompaniesFields {
  reference = 'company_id',
  lang_codes = 'lang_codes',
  company_need = 'company_need',
  company_rating = 'company_rating',
  subscription_start_date = 'subscription_start_date',
  account_type_id = 'account_type_id',
  id = 'id',
  status = 'status',
  central = 'central',
  type = 'type',
  created_at = 'created_at',
  show_in_search = 'show_in_search',
  legal_status = 'legal_status',
  name = 'name',
  short_name = 'short_name',
  year = 'year',
  description = 'description',
  city = 'city',
  street = 'street',
  street_number = 'street_number',
  locale_number = 'locale_number',
  post_code = 'post_code',
  post_seat = 'post_seat',
  district = 'district',
  voivodeship_id = 'voivodeship_id',
  nip = 'nip',
  phone = 'phone',
  email = 'e-mail',
  is_public_email = 'is_public_email',
  mail_contacts_count = 'mail_contacts_count',
  account_type_expires_at = 'account_type_expires_at',
  www = 'www',
  company_id = 'company_id',
  trade_id = 'trade_id',
  reference_id = 'reference_id',
  reference_custom_value = 'reference_custom_value',
  latitude = 'latitude',
  longitude = 'longitude',
  visits_counter = 'visits_counter',
  potential_search_counter = 'potential_search_counter',
  contact_counter = 'contact_counter',
  logo = 'logo',
  logo_dir = 'logo_dir',
  max_recommended = 'max_recommended',
  max_promoted = 'max_promoted',
  factory_post_code = 'factory_post_code',
  factory_post_seat = 'factory_post_seat',
  factory_street = 'factory_street',
  factory_street_number = 'factory_street_number',
  factory_locale_number = 'factory_locale_number',
  factory_voivodeship = 'factory_voivodeship',
  factory_phone = 'factory_phone',
  factory_email = 'factory_e-mail',
  factory_www = 'factory_www',
  is_factory_address = 'is_factory_address',
  slug_url = 'slug_url',
  slug_url_old = 'slug_url_old',
  seo_text_meta = 'seo_text_meta',
  seo_text_html = 'seo_text_html',
  seo_title = 'seo_title',
  plus_group = 'plus_group',
  seo_section_visible = 'seo_section_visible',
  seo_section_for_edit = 'seo_section_for_edit',
  is_complete = 'is_complete',
  show_similar = 'show_similar',
  is_similar_opened = 'is_similar_opened',
  is_archival = 'is_archival',
  is_r_account = 'is_r_account',
  is_trial = 'is_trial',
  branches = 'branches',
  available_positions_count = 'available_positions_count',
  is_cluster_mt = 'is_cluster_mt',
  is_internet_company_card = 'is_internet_company_card',
  users_ids = 'users_ids',
  is_enabled_seo = 'is_enabled_seo',
  block_google_index = 'block_google_index',
  is_limited = 'is_limited',
}
